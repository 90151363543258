@import url(https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://unpkg.com/tachyons/css/tachyons.min.css);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to right, #f7f7f7 0%, hsl(203, 73%, 78%) 100%); /* w3c */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.login-form {
  width: 400px;
  margin: 30px auto;
  margin-bottom: 15px;
  background: #f7f7f7;
  box-shadow: 0px 2px 2px rgba(78, 38, 38, 0.3);
  padding: 30px;
  position: center;
  margin-top: 100px;
}
.form-control, .login-btn {
  border-radius: 2px;
}
.input-group-prepend .fa {
  font-size: 18px;
}
.login-btn .btn:hover {
  font-size: 15px;
  font-weight: bold;
  min-height: 40px;
  opacity: 1;
}
.h6 {
 color: #dc6726; 

}





.truck {
  height: 50px;
  width: 50px; 
}

.googlemap{
  height: 400px;
  width: 800px; 
}

.footer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: #222222;
  padding: "20px";
    
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

