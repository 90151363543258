body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to right, #f7f7f7 0%, hsl(203, 73%, 78%) 100%); /* w3c */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.login-form {
  width: 400px;
  margin: 30px auto;
  margin-bottom: 15px;
  background: #f7f7f7;
  box-shadow: 0px 2px 2px rgba(78, 38, 38, 0.3);
  padding: 30px;
  position: center;
  margin-top: 100px;
}
.form-control, .login-btn {
  border-radius: 2px;
}
.input-group-prepend .fa {
  font-size: 18px;
}
.login-btn .btn:hover {
  font-size: 15px;
  font-weight: bold;
  min-height: 40px;
  opacity: 1;
}
.h6 {
 color: #dc6726; 

}


